<template>
  <v-row class="ma-3 pa-0" align-content="start">
    <v-col cols="auto">
      <v-sheet
          min-width="300px"
          max-width="300px"
          :height="height"
          elevation="2" rounded class="position-relative overflow-hidden">
        <v-tabs background-color="transparent">
          <v-tab>Не отвеченные</v-tab>
          <v-tab>В очереди</v-tab>
        </v-tabs>
        <v-sheet height="48px">
          <v-autocomplete
              v-model="userSelectedId"
              :items="users"
              :search-input.sync="usersText"
              solo
              flat
              placeholder="Поиск"
              hide-details="auto"
              item-text="name"
              item-value="id"
              class="mb-4"
          >
            <template v-slot:prepend-inner>
              <v-sheet width="24" height="24" class="d-flex align-center justify-center">
                <v-progress-circular v-if="userSearchState.isLoading" color="primary" size="16" width="2" indeterminate/>
                <v-icon v-else>mdi-magnify</v-icon>
              </v-sheet>
            </template>
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Не найдено</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:item="{item}">
              <v-list-item-content style="width:254px">
                <v-list-item-title class="font-weight-medium">{{item.lastName}} {{item.firstName}} {{item.lastName}}</v-list-item-title>
                <v-list-item-subtitle>
                  +{{item.phone}}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <template v-slot:selection="{item}">
              <div>{{item.lastName}} {{item.firstName}} {{item.lastName}}</div>
            </template>
          </v-autocomplete>
        </v-sheet>
        <v-divider/>
        <v-sheet class="contentAfterBar" ref="dialogs">
          <div v-for="(dialogs,index) in dialogPages" :key="'dialogPage'+index">
            <v-list class="py-0">
              <v-list-item
                  v-for="dialog1 in dialogs" :key="'dialog'+dialog1.id" @click="dialogLoad(dialog1.id)"
                  :class="dialog1.id===dialog.id ? 'v-list-item--active v-list-item--link' : ''"
              >
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">{{dialog1.name}}</v-list-item-title>
                  <v-list-item-subtitle>{{dialog1.dialogMessageLast.text}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <alert-view v-if="dialogPagesState.isSuccess && typeof this.dialogPages[1][0]==='undefined'" icon="mdi-information-outline" text="Не найдено" />
          <v-progress-circular v-if="dialogPagesState.isLoading" indeterminate color="primary" class="mt-4 mx-auto d-block"/>
        </v-sheet>
      </v-sheet>
    </v-col>
    <v-col>
      <v-sheet
          :height="height"
          elevation="2" rounded class="position-relative overflow-hidden">
        <v-toolbar dense elevation="0" color="transparent" max-height="48px">
          <v-toolbar-title class="body-2 font-weight-medium">
            <span v-if="dialog!==null" class="mr-2">{{dialog.name}}</span>
            <v-progress-circular v-if="messagePagesState.isLoading" size="16" width="3" indeterminate color="primary"/>
          </v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-divider/>
        <v-sheet :height="height-49-messTextHeight" class="overflow-y-auto pt-2" ref="messages">
          <alert-view v-if="messagePagesState.isSuccess" icon="mdi-information-outline" text="Ранних сообщений не найдено" />
          <div v-for="(messages,index) in messagePages.slice().reverse()" :key="'messagesPage'+index">
            <v-list class="py-0">
              <v-list-item v-for="message in messages" :key="'message'+message.id">
                <v-sheet
                    :class="(message.user.id===$store.state.user.id ? 'userMe' : 'userСompanion')+' mb-2 px-4 py-2'"
                    color="grey lighten-2" outlined rounded style="background-color:transparent!important"
                >
                  <div class="caption font-weight-medium muted1">
                    Админ
                  </div>
                  <div class="body-2" v-html="$tools.nl2br(message.text)"></div>
                  <div class="pt-1">
                    <v-btn
                        v-for="i1 in 2" :key="'messAttach'+i1"
                        depressed small width="100" :class="'mb-1 d-inline-block overflow-hidden'+(i1<2 ? ' mr-1' : '')">
                      Фото
                    </v-btn>
                  </div>
                  <v-icon color="green" small class="float-right" style="margin-top:2px">mdi-check-all</v-icon>
                  <span class="caption mr-1 float-right">{{$tools.dateTimeFormat(message.created, 'DD.MM.YYYY HH:mm')}}</span>
                </v-sheet>
              </v-list-item>
            </v-list>
          </div>
        </v-sheet>
        <v-divider/>
        <v-row no-gutters>
          <v-col cols="auto" class="pt-1 pl-2">
            <v-btn icon @click="resize"><v-icon>mdi-attachment</v-icon></v-btn>
          </v-col>
          <v-col>
            <v-textarea v-model="messText" ref="messText" solo flat auto-grow :height="32" placeholder="Текст сообщения"/>
          </v-col>
          <v-col cols="auto" class="pt-1 pr-1">
            <v-btn icon @click="resize"><v-icon>mdi-send</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
    <v-col cols="auto">
      <v-sheet
          min-width="300px"
          max-width="300px"
          :height="height"
          elevation="2" rounded class="position-relative overflow-hidden">
        <toolbar-view title="Шаблоны сообщений">
          <template v-slot:barActions>
            <v-btn depressed small @click="resize">Добавить</v-btn>
          </template>
        </toolbar-view>
        <v-divider/>
        <v-sheet class="overflow-y-auto contentAfterBar">
          <v-list v-if="mesTemplatesState.isSuccess && mesTemplates.length>0">
            <v-list-item v-for="i in 10" :key="'messTemplate'+i">
              <div>
                <v-btn icon class="float-right ml-2"><v-icon small>mdi-close</v-icon></v-btn>
                <div class="pa-2 body-2">
                  234werwerqwe qwe
                  qw qweqwe
                  e
                  q weqweqweeeeeeeeeee qweqw e
                  wer
                </div>
                <v-divider v-if="i<10"/>
              </div>
            </v-list-item>
          </v-list>
          <alert-view v-if="mesTemplatesState.isSuccess && mesTemplates.length===0" icon="mdi-information-outline" text="Не найдено" />
          <v-progress-circular v-if="mesTemplatesState.isLoading" indeterminate color="primary" class="mt-4 mx-auto d-block"/>
        </v-sheet>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import ToolbarView from "@/components/ToolbarView";
import AlertView from "@/components/AlertView";
import axios from "axios";

export default {
  components: {AlertView, ToolbarView},
  props: [],
  data: () => ({
    height:0,

    userSearchState: new State(),
    userSelectedId:null,
    users:[],
    usersText:null,

    dialogPagesState: new State(),
    dialogPage:1,
    dialogPages:[],

    dialog:null,
    messagePagesState: new State(),
    messagePage:1,
    messagePages: [],

    mesTemplatesState: new State(),
    mesTemplates: [],

    messText:null,
    messTextHeight:48,
  }),
  mounted() {
    this.resize();
    setTimeout(()=>{this.resize();},300);
    window.addEventListener('resize', ()=>{
      this.resize();
    }, true);

    /*
    this.dialogsState.stateLoading();
    this.messState.stateLoading();
    this.mesTemplatesState.stateLoading();
    setTimeout(()=>{
      this.dialogsState.stateSuccess(); this.dialogs=[1];
      this.messState.stateSuccess(); this.mess=[1];
      this.mesTemplatesState.stateSuccess(); this.mesTemplates=[1];
    },500);

     */
    this.dialogsLoad();
    this.$refs.dialogs.$el.addEventListener('scroll', ()=>{
      if(this.$refs.dialogs.$el.scrollHeight-this.$refs.dialogs.$el.scrollTop-this.$refs.dialogs.$el.clientHeight<200){
        this.dialogsLoad();
      }
    }, true);

    this.$refs.messages.$el.addEventListener('scroll', ()=>{
      if(this.$refs.messages.$el.scrollTop<200){
        this.messagesLoad();
      }
    }, true);
  },
  watch:{
    usersText(){
      this.userSearch();
    },
    userSelectedId(){
      this.dialogsLoad();
    },
    messText(){
      this.messTextHeight = this.$refs.messText.$el.getBoundingClientRect().height-30;
    },
  },
  methods:{
    userSearch(){
      this.userSearchState.stateLoading();
      axios.get(Api.host+'/user/?type=client&q='+this.usersText).then((r)=> {
        this.users = r.data.data;
        this.users.forEach((v)=>{
          v.name = v.lastName+' '+v.firstName+' '+v.secondName+' '+v.phone;
        });
        this.userSearchState.stateSuccess();
      })
      .catch(()=>{
        this.userSearchState.stateError();
      });
    },

    dialogsLoad(){
      if(this.dialogPagesState.isLoading) return null;
      if(this.dialogPage>1 && this.dialogPages[this.dialogPages.length-1].length===0) return null;

      this.dialogPagesState.stateLoading();
      let url = Api.host+'/dialog/?page='+this.dialogPage;
      if(this.userSelectedId===null){
        url +='&onPage=25';
      } else {
        url +='&userId='+this.userSelectedId+'&page=1&onPage=500';
      }

      axios.get(url)
          .then((r) =>{
            this.$set(this.dialogPages, this.dialogPage, r.data.data);
            this.dialogPage++;

            if(this.dialogPages[this.dialogPages.length-1].length>0)
              this.dialogLoad(this.dialogPages[this.dialogPages.length-1][0].id);
            this.dialogPagesState.stateSuccess();
          })
          .catch(e =>{
            Api.responseError(e)
            this.dialogPagesState.stateError();
          });
    },

    dialogLoad(id){
      this.dialogPages.forEach((v)=>{
        this.dialog = v.filter(v1=>v1.id===id)[0];
      });
      this.messagePage = 1;
      this.messagePages = [];

      this.messagesLoad();
    },

    messagesLoad(){
      if(this.dialog===null) return null;
      if(this.messagePagesState.isLoading) return null;
      if(this.messagePage>1 && this.messagePages[this.messagePages.length-1].length===0) return null;

      this.messagePagesState.stateLoading();
      axios.get(Api.host+'/dialog/'+this.dialog.id+'/message/?page='+this.messagePage+"&onPage=25")
          .then((r) =>{
            this.messagePagesState.stateSuccess();

            this.$set(this.messagePages, this.messagePage, r.data.data);
            if(this.messagePage===1){
              this.$nextTick(()=>{
                this.$refs.messages.$el.scrollTop = this.messagesHeight();
              });
            } else {
              let h = this.messagesHeight();
              this.$nextTick(()=>{
                this.$refs.messages.$el.scrollTop = this.$refs.messages.$el.scrollTop+this.messagesHeight()-h;
              });
            }
            this.messagePage++;
          })
          .catch(e =>{
            Api.responseError(e)
            this.messagePagesState.stateError();
          });
    },

    //tools
    resize(){
      this.height = window.innerHeight-64-48;
    },

    dialogsHeight(){
      let h = 0;
      for(let i=0;i<this.$refs.dialogs.$el.children.length;i++)
        h += this.$refs.dialogs.$el.children[i].clientHeight;
      return h;
    },
    messagesHeight(){
      let h = 0;
      for(let i=0;i<this.$refs.messages.$el.children.length;i++)
        h += this.$refs.messages.$el.children[i].clientHeight;
      return h;
    }
  }
}
</script>

<style lang="scss" scoped>
.contentAfterBar{
  bottom:0;
  top:97px;
  position:absolute;
  width:100%;
  overflow-y: auto;
}
.userMe{
  border-radius: 10px 0 10px 10px !important;
  margin-left:auto;
}
.userСompanion{
  border-radius: 0 10px 10px 10px !important;
}
</style>